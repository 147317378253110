import { useEffect, useState } from "react";
import moment from "moment/moment";
import { parseJsonData } from "../../../helper/common";
import "./Styles/MainMenuStyle.css";
import { restaurantMenu } from "../../../api/getRestaurantMenu";
import MainMenuLandscape from "./mainMenuLandscape";
import MainMenuPortrait from "./mainMenuPortrait";

/**
 *
 * @param props
 * @returns
 */
const MainMenu = (props) => {
  const { moduleSettings, block } = props;
  const { googleFontLink, parameters } = moduleSettings;
  const [menuData, setMenuData] = useState();
  const [displayPrice, setDisplayPrice] = useState(false);

  const titleColor =
    parameters?.title_1_color?.mapValue?.fields?.value?.stringValue;
  const soupTitle = parameters?.title_1?.mapValue?.fields?.value?.stringValue;
  const lang = parameters?.language_code?.mapValue?.fields?.value?.stringValue;
  const mealTitle = parameters?.title_2?.mapValue?.fields?.value?.stringValue;
  const fontColor =
    parameters?.font_color?.mapValue?.fields?.value?.stringValue;
  const currency = parameters?.currency?.mapValue?.fields?.value?.stringValue;
  const scrollEffect =
    parameters?.scroll_effect?.mapValue?.fields?.value?.stringValue;
  const duration =
    Number(parameters?.duration?.mapValue?.fields?.value?.stringValue) * 1000;
  const allergens = parseJsonData(
    parameters?.json_data?.mapValue?.fields?.value?.stringValue
  );
  const menuId =
    parameters?.restaurant_menu_id?.mapValue?.fields?.value?.stringValue;

  const presentationOrientation =
    parameters?.presentation_orientation?.mapValue?.fields?.value?.stringValue;

  useEffect(() => {
    const fetchData = async () => {
      const menu = await restaurantMenu(menuId);
      setMenuData(menu?.data?.metadata);
      setDisplayPrice(menu?.data?.displayPrice);
    };
    fetchData();
  }, []);

  let soups;
  let meals;
  if (menuData) {
    moment.locale("en");
    const todayDate = moment().format("Y-MM-DD");
    const todayDayName = moment().format("dddd").toLowerCase();
    const todaysMenu = menuData[todayDayName];
    if (todaysMenu) {
      if (todaysMenu.hasOwnProperty("date")) {
        if (todaysMenu["date"] === todayDate) {
          soups = todaysMenu["soup"];
          meals = todaysMenu["meal"];
        }
      } else {
      }
    }
  }

  const foodTitle = {
    color: titleColor,
  };

  const foodTextActive = {
    color: fontColor,
    fontWeight: "bold",
  };

  const foodText = {
    color: "#c6c5c4",
    textDecoration: "line-through",
    textDecorationColor: titleColor,
  };

  const soupImage = {
    width: "620px",
    marginBottom: "20px",
  };

  const mainStyle = {
    fontFamily: parameters?.google_font?.mapValue?.fields?.value?.stringValue,
    fontWeight:
      parameters?.google_font_variant?.mapValue?.fields?.value?.stringValue,
  };

  // update allergens
  if (soups) {
    soups.map((item, index) => {
      const attributes = item?.attributes;
      const result = updateAllergens(attributes, allergens, lang);
      item.attributes = result.join(",");
    });
  }

  if (meals) {
    meals.map((item, index) => {
      const attributes = item?.attributes;
      const result = updateAllergens(attributes, allergens, lang);
      item.attributes = result.join(",");
    });
  }
  console.log("meals", meals);
  if (presentationOrientation === "landscape") {
    return MainMenuLandscape(
      allergens,
      meals,
      soups,
      lang,
      foodTitle,
      foodTextActive,
      foodText,
      soupImage,
      mainStyle,
      scrollEffect,
      duration,
      displayPrice,
      currency,
      mealTitle,
      googleFontLink,
      soupTitle
    );
  } else {
    return MainMenuPortrait(
      allergens,
      meals,
      soups,
      lang,
      foodTitle,
      foodTextActive,
      foodText,
      soupImage,
      mainStyle,
      scrollEffect,
      duration,
      displayPrice,
      currency,
      mealTitle,
      googleFontLink,
      soupTitle
    );
  }
};

const updateAllergens = (attributes, allergens, lang) => {
  const returnData = [];
  const itemAttributes = attributes.split(",");
  for (const id of itemAttributes) {
    for (const key in allergens) {
      if (Object.hasOwnProperty.call(allergens, key)) {
        const element = allergens[key];
        const metadata = element?.metadata;
        const number = metadata?.number;
        if (number && number === id) {
          returnData.push(metadata?.number);
        }
      }
    }
  }
  return returnData;
};
export default MainMenu;
